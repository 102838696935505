import React from 'react'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import banner from './images/birdaal-makeup_banner.jpg'
import {Helmet} from "react-helmet"; 
 
import AOS from "aos";
import "aos/dist/aos.css";

	<Helmet> 
		   <title>The Best Bridal Makeup Saloon </title>
		   <meta name="description" content="Every girl wishes to be the most beautiful woman on her wedding day. Sabhreeya, the Best Bridal Makeup Saloon, will dazzle your wedding day with the perfect bridal appearance." />
  </Helmet>

const bridal_jewelry = [{
	  image :'./images/gallery-3.jpg',
	  alt_txt : 'Bridal Jewelry'
	},
	{
	  image :'./images/gallery-2.jpg',
	  alt_txt : 'Bridal Jewelry'
	},
	{
	  image :'./images/gallery-13.jpg',
	  alt_txt : 'Bridal Jewelry'
	},
	{
	  image :'./images/gallery-12.jpg',
	  alt_txt : 'Bridal Jewelry'
	},
	{
	  image :'./images/gallery-5.jpg',
	  alt_txt : 'Bridal Jewelry'
	},
	{
	  image :'./images/gallery-7.jpg',
	  alt_txt : 'Bridal Jewelry'
	}
	]

	
function bridal_makeup ()  {
	 AOS.init();
     AOS.refresh();
    return (
        <>
	


            <div className="common-banner">
		      <div className="phone_banner" style={{ background: 'url(./images/birdaal-makeup_banner-phone.jpg) no-repeat' }}></div>
		      <img src={banner} alt="About Us" className="img-fluid" width="1920" height="500"  />
			  <Container>
			   <Row>
			    <Col lg={12}>
			      <h1>Bridal Makeup</h1>
				    <Breadcrumb>
					  <Breadcrumb.Item as={Link} to="/" active>Home</Breadcrumb.Item>
					  <Breadcrumb.Item >Bridal Makeup</Breadcrumb.Item>
					</Breadcrumb>
			    </Col>
			   </Row>
			  </Container>
		    </div>
			
			<div className="content bridal-jewellery">
			   <Container >
				 <Row className="mb-1"> 
				   <Col xl={12} lg={12} data-aos="fade-down">
					  <div className="mb-5 text-center">
					   <h5>Bridal Makeup</h5>
						  <p className="text-center">Every girl wishes to be the most beautiful woman on her wedding day. Sabhreeya, the Best Bridal Makeup Saloon, will dazzle your wedding day with the perfect bridal appearance. Let's sprinkle some beauty love on you to make you appear gorgeous.</p>
					  </div>
					</Col>
				  </Row>
				  <Row>
				   {bridal_jewelry.map((c,index) =>{
					  return (
					    <Col xl={4} lg={6} data-aos="fade-up" className="mb-3">
						  <img src={c.image} alt={c.alt_txt} className="img-fluid" width="853" height="1280" />
						</Col>
					  );
					  })}
				  </Row>
				  
				  <Row className="mt-5 video-iframe">
				    <Col lg={4} md={6}>
					   <iframe width="100%" height="320" src="https://www.youtube.com/embed/EU5ni8lIc7M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</Col>
					 <Col lg={4} md={6}>
					   <iframe width="100%" height="320" src="https://www.youtube.com/embed/J5FA5byiS74" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					 </Col>
					 <Col lg={4} md={6}>
					   <iframe width="100%" height="320" src="https://www.youtube.com/embed/F7cHQ1aQNi0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					 </Col>
					  <Col lg={4} md={6}>
					  <iframe width="100%" height="320" src="https://www.youtube.com/embed/iUOwbIPISeA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					 </Col>
					  <Col lg={4} md={6}>
					  <iframe width="100%" height="320" src="https://www.youtube.com/embed/iUOwbIPISeA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					 </Col>
					 <Col lg={4} md={6}>
					   <iframe width="100%" height="320" src="https://www.youtube.com/embed/LP7LntT7A-c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					 </Col>
					  <Col lg={4} md={6}>
					  <iframe width="100%" height="320" src="https://www.youtube.com/embed/GTjaHvRegvQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					 </Col>
					  <Col lg={4} md={6}>
					   <iframe width="100%" height="320" src="https://www.youtube.com/embed/aGSbXEkERnU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					 </Col>
					  <Col lg={4} md={6}>
					   <iframe width="100%" height="320" src="https://www.youtube.com/embed/yj3cWEMI7s4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					  </Col>
				  </Row>
			  </Container>
			 </div>
        </>
    )
}

export default bridal_makeup
