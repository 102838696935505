import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter   as Router, Redirect, Switch, Route } from "react-router-dom";
import './index.css';
import Topbar from './topbar.js';
import Navbar from './navbar.js';
import Home from './home.js';


import About from './about'
import Jewellery from './bridal-jewellery'
import Lehnga from './bridal-lehnga'
import Bridal_Makeup from './bridal-makeup'
import Party_Makeup from './party-makeup'
import Engagement_Makeup from './engagement-makeup'
import Reception_Makeup from './reception-makeup'
import Skin_services from './skin-services'
import Nails from './nails'
import Hair from './hair'
import Contact from './contacts' 
import Portfolio from './portfolio'
import Package from './package'
import Footer from './footer.js'; 
import Salon from './the-salon'

 

 ReactDOM.render( 
	 
   <React.StrictMode>
         <Topbar/>
	    <Router >
	     <Navbar/> 
		  <Switch> 
			 <Route exact path="/" component={Home} />
		     <Route exact path="/the-salon" component={Salon} />
			 <Route exact path="/about" component={About} />
		     <Route exact path="/contacts" component={Contact} />
			 <Route exact path="/bridal-jewellery" component={Jewellery} />
			 <Route exact path="/bridal-lehnga" component={Lehnga} />
			 <Route exact path="/bridal-makeup" component={Bridal_Makeup} />
			 <Route exact path="/party-makeup" component={Party_Makeup} />
			 <Route exact path="/engagement-makeup" component={Engagement_Makeup} />
			 <Route exact path="/reception-makeup" component={Reception_Makeup} />
			 <Route exact path="/skin-services" component={Skin_services} />
			 <Route exact path="/nails" component={Nails} />
			 <Route exact path="/hair" component={Hair} />
			 <Route exact path="/portfolio" component={Portfolio} />
			 <Route exact path="/package" component={Package} />
			 <Redirect to="/" component={Home} />
			 <Route component={Home} />
			<Route path="*" component={Home} />
		  </Switch>
	  
	     <Footer/>
	    </Router>
  </React.StrictMode>,
  document.getElementById('root')
   
); 
