import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'

import lehnga from './images/lehnga.jpg'
import jewelley from './images/bridal-lehnga.jpg'
import AOS from "aos";
import "aos/dist/aos.css";


function bridal_jewellery() {
	 AOS.init();
     AOS.refresh();
	 
  return (
      <>
         <div className="bridal-jewellery">
		   <Container fluid>
		     <Row className="align-items-center"> 
			    <Col xl={3} lg={6} data-aos="fade-up-right">
				  <img src={jewelley} alt="Bridal Lehnga" className="img-fluid" width="730" height="1000" />
				</Col>
				<Col xl={3} lg={6} data-aos="fade-up-left">
				  <div className="bridal">
				     <h5>Bridal Jewellery</h5>
				    <p>Get ready for the compliments as well as the camera. You are the STAR of the event. Choose your Bridal Jewellery with us. We have an amazing Bridal Jewellery line from Kundan-Polki Necklaces, American Diamond Necklaces, Antique Gold & Temple Sets, Indo-Western Jewellery, Pearl Necklaces to Precious Beaded Necklaces.</p>
				   <Button  as={Link} to="/bridal-jewellery">Read More</Button>
				  </div>
				</Col>
			    <Col xl={3} lg={6} data-aos="fade-up-right">
				  <img src={lehnga} alt="Bridal Lehnga" className="img-fluid"  width="730" height="1000"/>
				</Col>
				<Col xl={3} lg={6} data-aos="fade-up-left">
				  <div className="bridal">
				    <h5>Bridal Lehnga</h5>
				  <p>Bridal Lehngas are available for rent at Sabhreeya. We have a variety of wedding attires that brings the best of high fashion apparel to you. Designer jewellery and bridal and non-bridal gowns are also available here. </p>
				  <Button  as={Link} to="/bridal-lehnga">Read More</Button>
				  </div>
				</Col>
			  </Row>
		  </Container>
		 </div>
	  </>
  );
}

export default bridal_jewellery;
 