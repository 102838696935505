import React from 'react'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Helmet} from "react-helmet"; 
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Contact_banner from './images/about-us.jpg'
import AOS from "aos";
import "aos/dist/aos.css";

const party_makeup_images = [{
	  image :'./images/party-makeup.jpg',
	  alt_txt : 'Bridal Jewelry'
	},
	{
	  image :'./images/party-makeup1.jpg',
	  alt_txt : 'Bridal Jewelry'
	},
	{
	  image :'./images/party-makeup2.jpg',
	  alt_txt : 'Bridal Jewelry'
	},
	{
	  image :'./images/party-makeup3.jpg',
	  alt_txt : 'Bridal Jewelry'
	},
	{
	  image :'./images/party-makeup4.jpg',
	  alt_txt : 'Bridal Jewelry'
	},
	{
	  image :'./images/party-makeup5.jpg',
	  alt_txt : 'Bridal Jewelry'
	}
	]
  function party_makeup () {
	 AOS.init();
     AOS.refresh();
    return (
        <>
		<Helmet> 
		   <title>Party Makeover Services | Best Party Makeover in Jalandhar | Best Party Makeover in Punjab</title>
		   <meta name="description" content="Getting ready for a party isn't just about the dress and hair – one of the most important aspects of your look is your makeup. To acquire appealing looks, try Sabhreeya's Party Makeover Services. In a very elegant setting, we will make you look and feel stunning." />
		 </Helmet>
            <div className="common-banner">
		      <div className="phone_banner" style={{ background: 'url(./images/about-phone.jpg) no-repeat' }}></div>
		      <img src={Contact_banner} alt="About Us" className="img-fluid"  width="1920" height="500" />
			  <Container>
			   <Row>
			    <Col lg={12}>
			      <h1>Party Makeup</h1>
				    <Breadcrumb>
					  <Breadcrumb.Item as={Link} to="/" active>Home</Breadcrumb.Item>
					  <Breadcrumb.Item >Party Makeup</Breadcrumb.Item>
					</Breadcrumb>
			    </Col>
			   </Row>
			  </Container>
		    </div>
			
			<div className="content bridal-jewellery">
			   <Container >
				 <Row className="mb-1"> 
				   <Col xl={12} lg={12} data-aos="fade-down">
					  <div className="mb-5 text-center">
					   <h5>Party Makeup</h5>
						  <p className="text-center">Getting ready for a party isn't just about the dress and hair – one of the most important aspects of your look is your makeup. To acquire appealing looks, try Sabhreeya's Party Makeover Services. In a very elegant setting, we will make you look and feel stunning. </p>
					  </div>
					</Col>
				  </Row>
				  <Row>
				   {party_makeup_images.map((c,index) =>{
					  return (
					    <Col xl={4} lg={6} data-aos="fade-up" className="mb-3">
						  <img src={c.image} alt={c.alt_txt} className="img-fluid" width="730" height="1000" />
						</Col>
					  );
					  })}
				   
				  </Row>
				  
				  <Row className="mt-5 video-iframe">
				    <Col lg={4} md={6}>
					  <iframe width="100%" height="320" src="https://www.youtube.com/embed/_1zCTIvkZe0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</Col>
					 <Col lg={4} md={6}>
					  <iframe width="100%" height="320" src="https://www.youtube.com/embed/OFWAxTP_5ug" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</Col>
					 <Col lg={4} md={6}>
					  <iframe width="100%" height="330" src="https://www.youtube.com/embed/MOQWj_3yg08" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</Col>
					<Col lg={4} md={6}>
					<iframe width="100%" height="320" src="https://www.youtube.com/embed/Q3JkvJ1U5CM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</Col>
					 
				  </Row>
			  </Container>
			 </div>
        </>
    )
}

export default party_makeup
