import React from 'react'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Helmet} from "react-helmet";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import banner from './images/reception-banner.jpg'
import AOS from "aos";
import "aos/dist/aos.css";

const engagement = [{
	  image :'./images/reception-1.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/reception-2.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/reception-3.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/reception-4.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/reception-5.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/reception-6.jpg',
	  alt_txt : 'Reception'
	}
	]
	
function reception() {
     AOS.init();
     AOS.refresh();
 
    return (
        <>
		<Helmet> 
		   <title>Best Reception Makeup Services | Bridal Makeup in Punjab | Best Reception Makeup Services in Jalandhar</title>
		   <meta name="description" content="Sabhreeya is the Best Makeup Saloon in Jalandhar. For a female, reception makeup is just as essential as bridal makeup, so every girl wants to appear captivating on their special day. Sabhreeya's Best Reception Makeup Services can make this event even more spectacular." />
		 </Helmet>
            <div className="common-banner">
		      <div className="phone_banner" style={{ background: 'url(./images/reception-banner-phone.jpg) no-repeat' }}></div>
		      <img src={banner} alt="About Us" className="img-fluid" />
			  <Container>
			   <Row>
			    <Col lg={12}>
			      <h1>Reception Makeup</h1>
				    <Breadcrumb>
					  <Breadcrumb.Item as={Link} to="/" active>Home</Breadcrumb.Item>
					  <Breadcrumb.Item>Reception Makeup</Breadcrumb.Item>
					</Breadcrumb>
			    </Col>
			   </Row>
			  </Container>
		    </div>
			
			<div className="content bridal-jewellery">
			   <Container >
				 <Row className="mb-1"> 
				   <Col xl={12} lg={12} data-aos="fade-down">
					  <div className="mb-5 text-center">
					   <h5>Reception Makeup</h5>
						  <p className="text-center">For a female, reception makeup is just as essential as bridal makeup, so every girl wants to appear captivating on their special day. Sabhreeya's Best Reception Makeup Services can make this event even more spectacular. </p>
					  </div>
					</Col>
				  </Row>
				  <Row>
				   {engagement.map((c,index) =>{
					  return (
					    <Col xl={4} lg={6} data-aos="fade-up" className="mb-3">
						  <img src={c.image} alt={c.alt_txt} className="img-fluid" width="853" height="1280"/>
						</Col>
					  );
					  })}
				   
				  </Row>
				  <Row className="mt-5 video-iframe">
				    <Col lg={4} md={6}>
					 <iframe width="100%" height="320" src="https://www.youtube.com/embed/GTjaHvRegvQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</Col>
					 
					 
					 
				  </Row>
			  </Container>
			 </div>
        </>
    )
}

export default reception
