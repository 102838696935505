import React from 'react'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Helmet} from "react-helmet";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import banner from './images/nail-banner.jpg'
 
import AOS from "aos";
import "aos/dist/aos.css";



const nail_art= [ {
	  image :'./images/nail-1.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/nail-2.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/nail-4.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/nail-3.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/nail-5.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/nail-6.jpg',
	  alt_txt : 'Reception'
	}     
	]


function nails() {
     AOS.init();
     AOS.refresh();
   

 return (
     <>
	   <Helmet> 
		   <title>Best Nails Extainsion Services in Jalandhar | Nail Art in Jalandhar</title>
		   <meta name="description" content="Sabhreeya is the Best Makeup Saloon in Jalandhar. Best Nails Extainsion Services in Jalandhar, Nail Art services in Jalandher" />
		 </Helmet>
            <div className="common-banner">
		      <div className="phone_banner" style={{ background: 'url(./images/nail-banner-phone.jpg) no-repeat' }}></div>
		      <img src={banner} alt="About Us" className="img-fluid"  width="1920" height="500" />
			  <Container>
			   <Row>
			    <Col lg={12}>
			      <h1>Nails</h1>
				    <Breadcrumb>
					  <Breadcrumb.Item as={Link} to="/" active>Home</Breadcrumb.Item>
					  <Breadcrumb.Item>Nails</Breadcrumb.Item>
					</Breadcrumb>
			    </Col>
			   </Row>
			  </Container>
		    </div>
			
			<div className="content bridal-jewellery">
			   <Container >
				 <Row className="mb-1"> 
				   <Col xl={12} lg={12} data-aos="fade-down">
					  <div className="mb-5 text-center">
					     <h5>Nails</h5>
						  
					  </div>
					</Col>
				  </Row>
				  <Row>
				   {nail_art.map((c,index) =>{
					  return (
					    <Col xl={4} lg={6} data-aos="fade-up" className="mb-3">
						  <img src={c.image} alt={c.alt_txt} className="img-fluid" width="730" height="1000"/>
						</Col>
					  );
					  })}
				   
				  </Row>
			  </Container>
			 </div>
        </>
   );
 }
 

export default nails;
 

 


