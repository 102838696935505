import React from 'react';
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ScrollToTopBtn from "./scroll-to-top";
import ScrollToTop from './footer_scroll_to_top';



export default function App() {
  const footer_links = [{
             link_name:'Home',
			 url:'/'
        },
		{
             link_name:'The Salon',
			 url:'/the-salon'
        },
		{
             link_name:'The Artist',
			 url:'/about'
        },
		{
             link_name:'Bridal Jewellery',
			 url:'/bridal-jewellery'
        },
		{
             link_name:'Bridal Lehenga',
			 url:'/bridal-lehnga'
        },
		{
             link_name:'Portfolio',
			 url:'/portfolio'
        },
		{
             link_name:'Contact Us',
			 url:'/contacts'
        }        
    ];
	
	const footer_serivces = [{
		  services_name : 'Bridal Makeup',
		  url:'/bridal-makeup'
	 },{
		  services_name : 'Party Makeup',
		  url:'/party-makeup'
	 },
	 {
		  services_name : 'Engagement Makeup',
		  url:'/engagement-makeup'
	 },
	 {
		  services_name : 'Reception Makeup',
		  url:'/reception-makeup'
	 },
	 {
		  services_name : 'Skin Services',
		  url:'/skin-services'
	 },
	 {
		  services_name : 'Nails Art',
		  url:'/nails'
	 },
	 {
		  services_name : 'Hair Style',
		  url:'/hair'
	 }
	 
	 ];
	 
 
  return (
    <>
	<div className="footer">
	 <Container fluid>
	   <Row>
	    <Col lg={10} className="m-auto">
		 <Row>
		   <Col xl={3} lg={3} md={6}>
		      <h3>Contact Us</h3>
			  <p><strong><i className="bi bi-geo-alt"></i> Location </strong></p>
			  <p>3rd Floor, Above KFC, Model Town, Jalandhar (Punjab)</p>
			  
			  <p><strong><i className="bi bi-telephone-inbound"></i> Call Us </strong></p>
			  <p> +91 90231-30000</p>
			  
			  <p><strong><i className="bi bi-envelope-open"></i> Email Us </strong></p>
			  <p> info@sabhreeya.com</p>
			  
			  <p><strong><i className="bi bi-clock-history"></i> Opening Time </strong></p>
			  <p> Mon - Sun 11:00am 19:30pm</p>
		   </Col>
		   <Col xl={3} lg={3} md={6}>
		     <h4>Important Links</h4>
			  <ul>
				  	<ScrollToTop/>
			    {footer_links.map((Linkl, index)=> {
					return(
					 <li><Link to={Linkl.url}>{Linkl.link_name}</Link></li>
					);
				 }) 
				}
			  </ul>
		   </Col>
		   <Col  xl={3} lg={3} md={6}>
		     <h5>Makeup Services</h5>
			  <ul>
				<ScrollToTop/>
			    {footer_serivces.map((Linkl, index)=> {
					return(
					 <li><Link key="" to={Linkl.url}>{Linkl.services_name}</Link></li>
					);
				 }) 
				} 
			  </ul>
		   </Col>
		   <Col xl={3} lg={3} md={6}>
			  <h6>Follow Us On</h6>
				  <div className="footer-icon">
					 <a href="https://www.facebook.com/sabhreeyamakeupartist"  target="_blank" rel="noreferrer"><i className="bi bi-facebook"></i></a>
					 <a href="https://www.instagram.com/sabhreeya/?hl=en" target="_blank" rel="noreferrer"><i className="bi bi-instagram"></i></a>
					 <a href="https://www.youtube.com/sabhreeya" target="_blank" rel="noreferrer"><i className="bi bi-youtube"></i></a>
					 <a href="https://twitter.com/sabhreeya" target="_blank" rel="noreferrer"><i className="bi bi-twitter"></i></a>
				  </div>
			   </Col>
			</Row>
		 </Col>
	   </Row>
	  </Container>
     </div>
	 
	 <div className="copyright">
	   <Container>
	    <Row>
	     <Col>
	       <p>© Copyright 2021. All Rights Reserved. </p>
	     </Col>
	     </Row>
	   </Container>
	 </div>
	  
	 
     <ScrollToTopBtn />
         
    </>
  );
}




 

 