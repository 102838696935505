import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom' 
import AOS from "aos";
import "aos/dist/aos.css";

function serivces() {
	
	 AOS.init();
     AOS.refresh();
	
	const service = [{
	 image : './images/bridal.jpg',
	 title : 'Bridal Makeup',
	 descript: 'Every girl wishes to be the most beautiful woman on her wedding day. Sabhreeya, the Best Bridal Makeup Saloon, will dazzle your wedding day with the perfect bridal appearance. Let us sprinkle some beauty love on you to make you appear gorgeous.',
	 url : '/bridal-makeup',
	 alt_txt : 'Bridal Makeup'
	},
	{
	 image : './images/party.jpg',
	 title : 'Party Makeup',
	 descript: "Getting ready for a party is not just about the dress and hair - one of the most important aspects of your look is your makeup. To acquire appealing looks, try Sabhreeya's Party Makeover Services. In a very elegant setting, we will make you look and feel stunning.",
	 url : '/party-makeup',
	 alt_txt : 'Party Makeup'
	},
	{
	 image : './images/engagement.jpg',
	 title : 'Engagement Makeup',
	 descript: "On a special event like your Engagement Day or Night, Sabhreeya knows how to improve your skin, features, and how accentuate them. We recommend several looks based on a variety of criteria such as your attire, time of day, stage lighting, and so on, in order to offer you the best camera-ready makeup.",
	 url : '/engagement-makeup',
	 alt_txt : 'Engagement Makeup'
	},
	{
	 image : './images/reception.jpg',
	 title : 'Reception Makeup',
	 descript: "For a female, reception makeup is just as essential as bridal makeup, so every girl wants to appear captivating on their special day. Sabhreeya's Best Reception Makeup Services can make this event even more spectacular.",
	 url : '/reception-makeup',
	 alt_txt : 'Reception Makeup'
	},
	{
	 image : './images/skin.jpg',
	 title : 'Skin Services',
	 descript: "Skin Services have a number of benefits, including enhancing skin tone, texture, look, and circulation while also boosting relaxation. Our skincare services are tailored to address all skin types and subjects with high-quality products that make your skin glowing.",
	 url : '/skin-services',
	 alt_txt : 'Skin Services'
	},
	{
	 image : './images/hair-style.jpg',
	 title : 'Hair',
	 descript: "Hairstyling is necessary to compliment your makeup. Sabhreeya will make your dream of having the best hairstylist in Jalandhar a reality. We take great delight in achieving your hair styling dreams.",
	 url : '/hair',
	 alt_txt : 'Hair'
	}
	
   ]
	
  return (
      <>
         <div className="services">
		   <Container >
		     <Row> 
			    <Col>
				  <h4>Makeup Services</h4>
				</Col>
			  </Row>
			   <Row> 
			   {service.map((c, index) => {
				   return (
					 <Col lg={4} md={6} data-aos="fade-up">
						<a className="service-box" href={c.url} >
						  <img src={c.image} alt={c.alt_txt} className="img-fluid" width="444" height="386" />
						</a>
						<div className="service-text">
						    <p>{c.title}</p>
							<p><span> {c.descript}</span></p>
							<Button  as={Link} to={c.url}>Read More</Button>
						</div>
					</Col>
				   );
			    }) 
			   }
			  
			 </Row>
		   </Container>
		 </div>
	  </>
  );
}

export default serivces;
 