import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import AOS from "aos";
import "aos/dist/aos.css";


export default function slide_textmonial() {
	
	AOS.init();
    AOS.refresh();
	
  const testimonial = [{
            image: './images/gurleen.png',
            content: "Wanted to say big thank you  to Renu di and entire team and great service you provided. Thank you so much from bottom of my heart for all you hard work. Not only you did beautiful makeup on mine but my younger sister looked beautiful as well. i have recommended your salon to everyone. Thanks so much for being apart for my day wonderful and beautiful thanks renu di",
            author: "Gurleen Kaur",
            alt_text: 'Gurleen'
        },
        {
			image: './images/customer.png',
            content: "Thank you so much Renu di for making me look so beautiful and making my special day more memorable for me. You are best and you do your work with full dedication which I have not seen in much people. You just don't work for money it is clear your work is your passion. Love you so much Renu di. Thank you once again",
            author: "Customer's feedback",
            alt_text: "Customers feedback"
        },
        {
            image: './images/customer1.png',
            content: "I just wanted to say thank you so much for making us all look so beautiful for the wedding. thank you renu di.",
            author: "Customer's 1 feedback",
            alt_text: "Customers feedback"
        },
        {
            image: './images/customer2.png',
            content: "I am little bit late for thanking you really i am very grateful to you. you did great work for me. i must say you have magic in your beautiful hands. Everybody liked me you made my both days very special word are nothings infront of your talent and hard working. You have very sweet nature really you are down to Earth. Personality thank you once again",
            author: "Customer's 2 feedback",
            alt_text: "Customers 2"
        },
		{
		  image: './images/customer3.png',
		  content: "I'd just like to say that you did an absolute fabulous job on my makeup, it made my special day a lot better because of the ongoing compliments I received from many, however I'm not to take the credit for all of them, you have to give credit where credit is due. And that's why I'm giving you all the credit for all you've done for me I really appreciate it, a huge Thank you and I'll highly recommend you to others of-course !",
		  author: "Customer 3 Feedback",
		  alt_text: "Customer 3"
		}
    ];
 
 
 
  return (
    <>
	<div className="testimonials">
	 <div className="testimonails-background" style={{ background: 'url(./images/testimonial-background.jpg) no-repeat'}}></div>
	 <Container>
	    <Row>
		 <Col lg={8} className="m-auto" data-aos="zoom-in-up">
		  <Carousel fade interval={1000} controls={false} indicators={true}>
			{testimonial.map((c, index) => {
			   return (
			 
              <Carousel.Item interval={5000} >
				  <div className="testimonial-box">
				    <img src={c.image} alt={c.alt_text} className="img-fluid" height="100" height="100"/>
				    <p><i className="bi bi-chat-left-quote"></i> {c.content}</p>
				    <p><strong>- {c.author}</strong></p>
				  </div>
			  </Carousel.Item>
			   );
			})}
		  </Carousel>
	     </Col>
		
	   </Row>
	 </Container>
    </div>
	</>
  );
}
 