import React from 'react'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Helmet} from "react-helmet";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Contact_banner from './images/about-us.jpg'
import AOS from "aos";
import "aos/dist/aos.css";

 
  function party_makeup () {
	 AOS.init();
     AOS.refresh();
    return (
        <>
		<Helmet> 
		   <title>Bridal Makeup Charges in Jalandhar | Bridal Makeup Packages Price List</title>
		   <meta name="description" content="Sabhreeya is the Best Makeup Saloon in Jalandhar" />
		 </Helmet>
            <div className="common-banner">
		      <div className="phone_banner" style={{ background: 'url(./images/about-phone.jpg) no-repeat' }}></div>
		      <img src={Contact_banner} alt="About Us" className="img-fluid" width="1920" height="500" />
			  <Container>
			   <Row>
			    <Col lg={12}>
			      <h1>Package</h1>
				    <Breadcrumb>
					  <Breadcrumb.Item as={Link} to="/" active>Home</Breadcrumb.Item>
					  <Breadcrumb.Item >Package</Breadcrumb.Item>
					</Breadcrumb>
			    </Col>
			   </Row>
			  </Container>
		    </div>
			
			<div className="content pricing">
			   <Container>
				 <Row className="mb-1"> 
				   
					
					<Col xl={4} lg={4} data-aos="fade-down">
					  <div className="mb-5 text-center">
					     <div className="card text-center px-3 mb-4">
							<span className="h6">Platinum Pre Bridal List at &#8377; 18000</span>
							 <div className="card-body p-0">
								<ul className="list-unstyled mb-4">
								  <li><strong>Services </strong><strong>Quantity</strong></li>
								  <li><span>Facial </span><span>4</span></li>
								  <li><span>Fcae Bleach</span><span>2</span></li>
								  <li><span>Manicure & Pedicure with Spa</span> <span>2</span></li>
								  <li><span>Arms & Legs Chocolate Waxing </span><span>1</span></li>
								  <li><span>Full Body Ricca Waxing</span><span>1</span></li>
								  <li><span>Head Massage</span><span>1</span></li>
								  <li><span>Hair Spa</span><span>2</span></li>
								  <li><span>Threading Forehead, Upperlips</span><span>2</span></li>
								</ul>
							 </div>
						</div>
					  </div>
					</Col>
					<Col xl={4} lg={4} data-aos="fade-down">
					  <div className="mb-5 text-center">
					     <div className="card text-center px-3 mb-4">
							<span className="h6">Renu Angural Makeup List</span>
							 <div className="card-body p-0">
								<ul className="list-unstyled mb-4">
								  <li><strong>Makeup Type </strong><strong className="bride">Engagement/Bridal/Reception</strong></li>
								  <li><span>MAC </span><span>&#8377; 20000</span></li>
								  <li><span>HD </span><span>&#8377; 24000</span></li>
								  <li><span>Air Brush</span> <span>&#8377; 27000</span></li>
								  <li><span>Silk Look </span><span>&#8377; 35000</span></li>
								  <li><span>Sabhreeys's Celebrity Look </span><span>&#8377; 50000</span></li>
								  <li><span>Sabhreeys's Signature Look </span><span>&#8377; 65000</span></li>
								</ul>
							 </div>
						</div>
					  </div>
					</Col>
					<Col xl={4} lg={4} data-aos="fade-down">
					  <div className="mb-5 text-center">
					     <div className="card text-center px-3 mb-4">
							<span className="h6">Gold Pre Bridal List at &#8377; 12000</span>
							 <div className="card-body p-0">
								<ul className="list-unstyled mb-4">
								  <li><strong>Services</strong><strong>Quantity</strong></li>
								  <li><span>Facial </span><span>4</span></li>
								  <li><span>Face Bleach</span><span>2</span></li>
								  <li><span>Manicure & Pedicure Regular</span><span>2</span></li>
								  <li><span>Full Body Chocolate Waxing </span><span>1</span></li>
								  <li><span>Head Massage</span><span>1</span></li>
								  <li><span>Hair Spa</span><span>2</span></li>
								  <li><span>Threading Forhead, Upperlips</span><span>2</span></li>
								</ul>
								 
							 </div>
						</div>
					  </div>
					</Col>
				  </Row>
				  <Row className="my-5">
				   <Col xl={6} lg={6} data-aos="fade-down">
					  <div className="mb-5 text-center">
					     <div className="card text-center px-3 mb-4">
							<span className="h6">Senior Artist Makeup List</span>
							 <div className="card-body p-0">
								<ul className="list-unstyled mb-4">
								  <li>
									  <strong>Makeup Type</strong>
									  <strong>Engagement Makeup</strong> 
									  <strong>Bridal Makeup</strong>
								  </li>
								  <li>
								    <span>Makeup Studio </span>
								    <span>&#8377; 10000</span>
								    <span>&#8377; 13000</span>
								   </li>
								   <li>
								     <span>MAC </span>
								     <span>&#8377; 12000</span>
								     <span>&#8377; 17000</span>
								   </li>
								    <li>
								     <span>HD </span>
								     <span>&#8377; 15000</span>
								     <span>&#8377; 20000</span>
								   </li>
								    <li>
								     <span>Air Brush </span>
								     <span>&#8377; 17000</span>
								     <span>&#8377; 19000</span>
								   </li>
								    <li>
								    <span>Silk Look </span>
								    <span>&#8377; 20000</span>
								    <span>&#8377; 25000</span>
								   </li>
								</ul>
								 
							 </div>
						</div>
					  </div>
					</Col>
				    <Col xl={6} lg={6} data-aos="fade-down">
					  <div className="mb-5 text-center">
					     <div className="card text-center px-3 mb-4">
							<span className="h6">Party Makeup List</span>
							 <div className="card-body p-0">
								<ul className="list-unstyled mb-4">
								  <li>
									  <strong>Makeup Type</strong>
									  <strong>Senior Artist Makeup</strong> 
									  <strong>Renu Angural</strong>
								  </li>
								  <li>
								    <span>PAC </span>
								    <span>&#8377; 4000</span>
								    <span>-</span>
								   </li>
								   <li>
								    <span>Makeup Studio </span>
								    <span>&#8377; 6000</span>
								    <span>-</span>
								   </li>
								   <li>
								     <span>MAC </span>
								     <span>&#8377; 7500</span>
								     <span>-</span>
								   </li>
								    <li>
								     <span>HD </span>
								     <span>&#8377; 9500</span>
								     <span>-</span>
								   </li>
								    <li>
								     <span>Air Brush </span>
								     <span>&#8377; 9000</span>
								     <span>&#8377; 18000</span>
								   </li>
								    <li>
								    <span>Silk Look </span>
								    <span>&#8377; 12000</span>
								    <span>&#8377; 22000</span>
								   </li>
								</ul>
								 
							 </div>
						</div>
					  </div>
					</Col>
				  </Row>
			   </Container>
			 </div>
        </>
    )
}

export default party_makeup
