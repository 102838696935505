import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import renu from './images/Renu-pic.jpg'
import AOS from "aos";
import "aos/dist/aos.css";
 
function artist() {
     AOS.init();
     AOS.refresh();
 
  return (
      <>
         <div className="theartist">
		   <Container>
		     <Row> 
			    <Col lg={7}>
				  <div className="box" data-aos="fade-right">
				   <h2>The Makeover Artist - Renu</h2>
				   <p>
				     Renu, Makeover Artist in Jalandhar, Punjab India is a well known and famous makeup artist who rules the makeup industry worldwide and creates magic with her skills and makes everyone spellbound with her extraordinary talent. Optimally praised as the Best Makeup Artist in Jalandhar, Punjab India, and around the globe with her modernized magic and establishment for style and beauty totally follows Perfection.  </p>

 <p>Always dedicated to the complete picture - the most significant cosmetics, hairstyle, and accessories and believes every bit and element should be synergistic to weave an eccentric portrait of total beauty with prominent style.  Renu passion and love for flawless makeover no doubt makes her stand out exceptionally and unmatched in the field of the makeup industry. </p>
				   
				   <Button className="btn" as={Link} to="/about" >Read More</Button>
				  </div>
				</Col>
				 <Col lg={5} className="aos-all" data-aos="fade-left" >
				   <img src={renu} alt="The Artist" className="img-fluid" width="800" height="950"/>
				</Col>
			 </Row>
		   </Container>
		 </div>
	  </>
  );
}

export default artist;
// JavaScript Document// JavaScript Document