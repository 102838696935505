import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Contact_banner from './images/contact-us.jpg'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
 

function contact (){
	 AOS.init();
     AOS.refresh();
    return (
        <>
		 <Helmet> 
		   <title>Sabhreeya | Contact Us</title>
		   <meta name="description" content="Sabhreeya is the Best Makeup Saloon in Jalandhar" />
		 </Helmet>
		  <div className="common-banner">
		      <div className="phone_banner" style={{ background: 'url(./images/contact-phone.jpg) no-repeat' }}></div>
		      <img src={Contact_banner} alt="contact Us" className="img-fluid" width="1920" height="500"  />
			  <Container>
			   <Row>
			    <Col lg={12}>
			      <h1>Contact Us</h1>
				    <Breadcrumb>
					  <Breadcrumb.Item as={Link} to="/">Home</Breadcrumb.Item>
					  <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
					</Breadcrumb>
			    </Col>
			   </Row>
			  </Container>
		  </div>
		  <div className="contact-content">
		    <Container>
			 <Row>
			   <Col lg={12}>
			     <h2>Contact Information</h2>
				   <Row>
				    <Col lg={3} md={6} data-aos="fade-left">
					  <div className="box">
					    <p><strong><i className="bi bi-geo-alt"></i> Location </strong></p>
				        <p>3rd Floor, Above KFC, Model Town, Jalandhar (Punjab)</p>
					  </div>
					</Col>
					<Col lg={3} md={6} data-aos="fade-up">
					  <div className="box">
					     <p><strong><i className="bi bi-telephone-inbound"></i> Call Us </strong></p>
				        <p> +91 90231-30000</p>
					  </div>
					</Col>
					<Col lg={3} md={6} data-aos="fade-down">
					  <div className="box">
					    <p><strong><i className="bi bi-envelope-open"></i> Email Us </strong></p>
				        <p> info@sabhreeya.com</p>
					  </div>
					</Col>
					<Col lg={3} md={6} data-aos="fade-right">
					  <div className="box">
					    <p><strong><i className="bi bi-clock-history"></i> Opening Time </strong></p>
				        <p> Mon - Sun 11:00am 19:30pm</p>
					  </div>
					</Col>
				  </Row>
			    </Col>
			  </Row>
			  <Row className="mt-5">
			   <Col lg={6}>
			    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13635.582053882097!2d75.5769435!3d31.3066329!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xebbe4cfa95393c0f!2sSabhreeya%20Saloon!5e0!3m2!1sen!2sin!4v1634707572353!5m2!1sen!2sin" width="100%" height="550"   loading="lazy">Sabhreeya Saloon</iframe>
			   </Col>
			   <Col lg={6}>
			    <div  className="contact-form">
				  <h3>Get in Touch</h3>
				 
				  <Form>
				   <Row className="align-items-center">
					<Col md={6} className="mb-3">
					  <Form.Label htmlFor="FirstName" visuallyHidden>Name </Form.Label>
					  <Form.Control id="FirstName" name="first_name" placeholder="First Name" />
					</Col>
					<Col md={6} className="mb-3">
					  <Form.Label htmlFor="LastName" visuallyHidden>Username </Form.Label>
					  <Form.Control id="LastName" name="last_name" placeholder="Last Name" />
					</Col>
					<Col md={6} className="mb-3">
					  <Form.Label htmlFor="PhoneNo" visuallyHidden>Phone No </Form.Label>
					  <Form.Control id="PhoneNo" name="phone" placeholder="Phone No" />
					</Col>
					<Col md={6} className="mb-3">
					  <Form.Label htmlFor="Email" visuallyHidden>Email </Form.Label>
					  <Form.Control id="Email" name="email" placeholder="Email" />
					</Col>
					<Col md={12} className="mb-3">
					  <Form.Label htmlFor="Message" visuallyHidden>Email </Form.Label>
					  <Form.Control as="textarea" rows="4" id="Message" name="message" placeholder="Message" />
					</Col>
					 
					<Col className="mb-3">
					  <Button type="submit" name="submit">Submit</Button>
					</Col>
				  </Row>
				 </Form>
				</div>
			   </Col>
			  </Row>
			</Container>
		  </div>  
	    </>
    )
}

export default contact
