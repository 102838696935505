import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet"; 
import Banner from './images/lehnga-banner.jpg'

import Lehnga from './images/lehnga/1.jpg'
import Lehnga1 from './images/lehnga/2.jpg'
import Lehnga2 from './images/lehnga/3.jpg'
import Lehnga3 from './images/lehnga/4.jpg'
import Lehnga4 from './images/lehnga/5.jpg'
import Lehnga22 from './images/lehnga/23.jpg'
import Lehnga5 from './images/lehnga/6.jpg'
import Lehnga6 from './images/lehnga/7.jpg'
import Lehnga7 from './images/lehnga/8.jpg'
import Lehnga8 from './images/lehnga/9.jpg'
import Lehnga9 from './images/lehnga/10.jpg'
import Lehnga10 from './images/lehnga/11.jpg'
import Lehnga11 from './images/lehnga/12.jpg'
import Lehnga12 from './images/lehnga/13.jpg'
import Lehnga13 from './images/lehnga/14.jpg'
import Lehnga14 from './images/lehnga/15.jpg'
import Lehnga15 from './images/lehnga/16.jpg'
import Lehnga16 from './images/lehnga/17.jpg'
import Lehnga17 from './images/lehnga/18.jpg'
import Lehnga18 from './images/lehnga/19.jpg'
import Lehnga19 from './images/lehnga/20.jpg'
import Lehnga20 from './images/lehnga/21.jpg'
import Lehnga21 from './images/lehnga/22.jpg'
import Lehnga23 from './images/lehnga/24.jpg'



import AOS from "aos";
import "aos/dist/aos.css";
 
 
 
const lehnga_gallery = [
	{ image:<img src={Lehnga} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga1} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga2} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga3} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga4} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga22} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga6} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga5} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga9} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga7} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga8} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga10} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga11} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga12} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga13} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga14} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga15} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga16} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga17} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga18} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga20} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga19} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga21} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> },
	{ image:<img src={Lehnga23} alt="Bridal Lehnga" className="img-fluid" width="600" height="900" /> }
 
 ]

function bridal_lehnga () {
	 AOS.init();
     AOS.refresh();
    return (
        <>
		 <Helmet> 
		   <title>Best Bridal Lehenga in Jalandhar | Designer Lehenga on Rent</title>
		   <meta name="description" content="Sabhreeya is the Best Makeup Saloon in Jalandhar. Bridal Lehngas are available for rent at Sabhreeya. We have a variety of wedding attires that brings the best of high fashion apparel to you. Designer jewellery and bridal and non-bridal gowns are also available here." />
		 </Helmet>
          <div className="common-banner">
		      <div className="phone_banner" style={{ background: 'url(./images/bridal-lehnga-phone.jpg) no-repeat' }}></div>
		      <img src={Banner} alt="Bridal Lehnga" className="img-fluid"  width="1920" height="500" />
			  <Container>
			   <Row>
			    <Col lg={12}>
			      <h1>Bridal Lehenga</h1>
				    <Breadcrumb>
					  <Breadcrumb.Item as={Link} to="/">Home</Breadcrumb.Item>
					  <Breadcrumb.Item active>Bridal Lehenga</Breadcrumb.Item>
					</Breadcrumb>
			    </Col>
			   </Row>
			  </Container>
		    </div> 
			
			<div className="content bridal-jewellery">
			   <Container >
				 <Row className="mb-1"> 
				   <Col xl={12} lg={12} data-aos="fade-down">
					  <div className="mb-5 text-center">
					   <h5>Bridal Lehnga</h5>
						  <p className="text-center">Bridal Lehngas are available for rent at Sabhreeya. We have a variety of wedding attires that brings the best of high fashion apparel to you. Designer jewellery and bridal and non-bridal gowns are also available here.</p>
					  </div>
					</Col>
				  </Row>
				  <Row>
				    {lehnga_gallery.map((c, index) => {
						return (
						   <Col xl={4} lg={6} data-aos="fade-up" className="mb-3">
							   {c.image}  
						  </Col>
						 );
					  })
					 }
				  </Row>
				   <Row className="video-iframe">
				    <Col lg={4} md={6}>
					  <iframe width="100%" height="320" src="https://www.youtube.com/embed/yj3cWEMI7s4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</Col>
					<Col lg={4} md={6}>
					  <iframe width="100%" height="320" src="https://www.youtube.com/embed/U-m5cp8Ijhw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</Col> 
					 
					 
				  </Row>
			  </Container>
			 </div>
			
			  
        </>
    )
}

export default bridal_lehnga
