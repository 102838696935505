import React from 'react'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Helmet} from "react-helmet";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import banner from './images/skin-services-banner.jpg'
import AOS from "aos";
import "aos/dist/aos.css";

const skin_services= [ {
	  image :'./images/skin-1.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/skin-2.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/skin-3.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/skin-4.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/skin-5.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/skin-6.jpg',
	  alt_txt : 'Reception'
	} 
	]

function skin() {
     AOS.init();
     AOS.refresh();
 
    return (
        <>
		<Helmet> 
		   <title>Best Skin Services in Jalandhar | Skin Care Services in Jalandhar | Beauty Services in Punjab</title>
		   <meta name="description" content="Sabhreeya is the Best Makeup Saloon in Jalandhar. Skin Services have a number of benefits, including enhancing skin tone, texture, look, and circulation while also boosting relaxation. Our skincare services are tailored to address all skin types and subjects with high-quality products that make your skin glowing." />
		 </Helmet>
            <div className="common-banner">
		      <div className="phone_banner" style={{ background: 'url(./images/skin-services-banner-phone.jpg) no-repeat' }}></div>
		      <img src={banner} alt="About Us" className="img-fluid"  width="1920" height="500" />
			  <Container>
			   <Row>
			    <Col lg={12}>
			      <h1>Skin Services</h1>
				    <Breadcrumb>
					  <Breadcrumb.Item as={Link} to="/" active>Home</Breadcrumb.Item>
					  <Breadcrumb.Item>Skin Services</Breadcrumb.Item>
					</Breadcrumb>
			    </Col>
			   </Row>
			  </Container>
		    </div>
			
			<div className="content bridal-jewellery">
			   <Container >
				 <Row className="mb-1"> 
				   <Col xl={12} lg={12} data-aos="fade-down">
					  <div className="mb-5 text-center">
					   <h5>Skin Services</h5>
						  <p className="text-center">Skin Services have a number of benefits, including enhancing skin tone, texture, look, and circulation while also boosting relaxation. Our skincare services are tailored to address all skin types and subjects with high-quality products that make your skin glowing. </p>
					  </div>
					</Col>
				  </Row>
				  <Row>
				   {skin_services.map((c,index) =>{
					  return (
					    <Col xl={4} lg={6} data-aos="fade-up" className="mb-3">
						  <img src={c.image} alt={c.alt_txt} className="img-fluid" width="853" height="1280"/>
						</Col>
					  );
					  })}
				   
				  </Row>
				   <Row className="mt-5 video-iframe">
				    <Col lg={4} md={6}>
					 <iframe width="100%" height="320" src="https://www.youtube.com/embed/GTjaHvRegvQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</Col>
					 
					 
					 
				  </Row>
			  </Container>
			 </div>
        </>
    )
}

export default skin
