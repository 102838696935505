import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './index.css';

export default function app() {
	const slider = [{
		  slide_images : './images/slide2.jpg',
		  alt_text: 'Silder4 '
		},
		{
		  slide_images : './images/slide6.jpg',
		  alt_text: 'Silder6'
		},
		{
		  slide_images : './images/slide4.jpg',
		  alt_text: 'Silder2'
		},
		{
		  slide_images : './images/slide5.jpg',
		  alt_text: 'Silder5'
		},
		{
		  slide_images : './images/slide3.jpg',
		  alt_text: 'Silder3'
		}
		];
  return (
      <>
       <div className="slider" variant="dark" >
		   <Carousel controls={true} fade nextLabel={false} prevLabel={false} indicators={true}>
			{slider.map((c, imdex)=> {
				return(
				 <Carousel.Item interval={3500}>
				    <img src={c.slide_images} alt={c.alt_text} width="1920" height="800" className="img-fluid" />
				</Carousel.Item>
				);
			  })}
		  </Carousel>
	    </div>
	  </>
  );
}

 
// JavaScript Document// JavaScript Document