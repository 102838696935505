import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import AOS from "aos";
import "aos/dist/aos.css";
 

function already_beautiful() {
	AOS.init();
    AOS.refresh();
  return (
      <>
         <div className="already-beautiful">
		  <div className="already-beautiful-background" style={{ background: 'url(./images/slide.jpg) no-repeat' }}></div>
		   <Container data-aos="zoom-in">
		     <Row> 
			    <Col>
				  <div className="box">
				   <h3>You are already beautiful.<br/> Makeup only exists to accentuate your natural beauty</h3>
				   <p>Our ultimate aim is our client's happiness in terms of both quality and experience. We use high-quality makeup to assure that there are no side effects on our customer's skin. We've been working hard to help individuals to express themselves in the best possible light so that the best version of themselves shines through. We're here to assist you on one of the most important days of your life. </p>
 
				   </div>
				</Col>
			  </Row>
		   </Container>
		 </div>
	  </>
  );
}

export default already_beautiful;
// JavaScript Document// JavaScript Document