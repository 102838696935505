import React from 'react'
 
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Banner from './images/portfolio.jpg'
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet";
import AOS from 'aos';
import "aos/dist/aos.css";


const gallery = [{
          image: './images/gallery-1.jpg',
            alt_text: 'Gallery'
        },
		{
            image: './images/gallery-2.jpg',
            alt_text: 'Gallery'
        } ,
		{
            image: './images/gallery-3.jpg',
            alt_text: 'Gallery'
        } ,
		{
            image: './images/gallery-4.jpg',
            alt_text: 'Gallery'
        },
		{
            image: './images/gallery-5.jpg',
            alt_text: 'Gallery'
        },
		{
            image: './images/gallery-6.jpg',
            alt_text: 'Gallery'
        },
		{
            image: './images/gallery-7.jpg',
            alt_text: 'Gallery'
        },
		{
            image: './images/gallery-8.jpg',
            alt_text: 'Gallery'
        },
		{
            image: './images/gallery-9.jpg',
            alt_text: 'Gallery'
        },
		{
            image: './images/gallery-10.jpg',
            alt_text: 'Gallery'
        },
		{
            image: './images/gallery-11.jpg',
            alt_text: 'Gallery'
        },
		{
            image: './images/gallery-12.jpg',
            alt_text: 'Gallery'
        }        
    ];
	
 function Portfolio(){
	 AOS.init();
     AOS.refresh();
    return (
         <>
		 <Helmet> 
		   <title>Sabhreeya - Portfolio</title>
		   <meta name="description" content="Sabhreeya is the Best Makeup Saloon in Jalandhar" />
		 </Helmet>
		 
		   <div className="common-banner">
		      <div className="phone_banner" style={{ background: 'url(./images/portfolio-mobile.jpg) no-repeat' }}></div>
		      <img src={Banner} alt="Portfolio" className="img-fluid"  width="1920" height="500" />
			  <Container>
			   <Row>
			    <Col lg={12}>
			      <h1>The Artists Portfolio</h1>
				    <Breadcrumb>
					  <Breadcrumb.Item as={Link} to="/">Home</Breadcrumb.Item>
					  <Breadcrumb.Item active>The Artists Portfolio</Breadcrumb.Item>
					</Breadcrumb>
			    </Col>
			   </Row>
			  </Container>
		    </div> 
			
			<div className="gallery">
			 <Container>
				<Row>
				   <Col lg={12} className="m-auto">
					 <Row>
					   {gallery.map((c, index) => {
						return (
							<Col xl={4} lg={6} md={6} data-aos="zoom-out-up">
							  <div className="gallery-box">
								<img src={c.image} alt={c.alt_text} className="img-fluid"  width="853" height="1280" />
							   </div>
							 </Col>
							);
						})}
					 </Row>
				  </Col>
				</Row>
			  </Container>
			</div>
		   
	     </>
	  )
}

export default Portfolio
