import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet"; 
import Banner from './images/the-salon.jpg'

 import Salon from './images/salon/9.jpg'
 import Salon1 from './images/salon/1.jpg'
 import Salon2 from './images/salon/2.jpg'
 import Salon3 from './images/salon/3.jpg'
 import Salon4 from './images/salon/4.jpg'
 import Salon5 from './images/salon/5.jpg'
 import Salon6 from './images/salon/6.jpg'
 import Salon7 from './images/salon/7.jpg'
 import Salon8 from './images/salon/8.jpg'
 import Salon9 from './images/salon/10.jpg'
import Salon10 from './images/salon/11.jpg'
 import Salon11 from './images/salon/12.jpg'
 



import AOS from "aos";
import "aos/dist/aos.css";
 
 
 
const lehnga_gallery = [
	  { image:<img src={Salon} alt="The Bridal Salon" className="img-fluid" width="600" height="900" /> },
      { image:<img src={Salon1} alt="The Bridal Salon" className="img-fluid" width="600" height="900" /> },
      { image:<img src={Salon2} alt="The Bridal Salon" className="img-fluid" width="600" height="900" /> },
      { image:<img src={Salon3} alt="The Bridal Salon" className="img-fluid" width="600" height="900" /> },
      { image:<img src={Salon4} alt="The Bridal Salon" className="img-fluid" width="600" height="900" /> },
      { image:<img src={Salon5} alt="The Bridal Salon" className="img-fluid" width="600" height="900" /> },
      { image:<img src={Salon6} alt="The Bridal Salon" className="img-fluid" width="600" height="900" /> },
      { image:<img src={Salon7} alt="The Bridal Salon" className="img-fluid" width="600" height="900" /> },
      { image:<img src={Salon8} alt="The Bridal Salon" className="img-fluid" width="600" height="900" /> },
      { image:<img src={Salon9} alt="The Bridal Salon" className="img-fluid" width="600" height="900" /> },
      { image:<img src={Salon10} alt="The Bridal Salon" className="img-fluid" width="600" height="900" /> },
      { image:<img src={Salon11} alt="The Bridal Salon" className="img-fluid" width="600" height="900" /> }
       
 ]

function bridal_lehnga () {
	 AOS.init();
     AOS.refresh();
    return (
        <>
		 <Helmet> 
		   <title>Sabhreeya | The Salon</title>
		   <meta name="description" content="Sabhreeya is the Best Makeup Saloon in Jalandhar" />
		 </Helmet>
          <div className="common-banner">
		      <div className="phone_banner" style={{ background: 'url(./images/the-salon-phone.jpg) no-repeat' }}></div>
		      <img src={Banner} alt="The Bridal Salon" className="img-fluid"  width="1920" height="500" />
			  <Container>
			   <Row>
			    <Col lg={12}>
			      <h1>The Salon</h1>
				    <Breadcrumb>
					  <Breadcrumb.Item as={Link} to="/">Home</Breadcrumb.Item>
					  <Breadcrumb.Item active>The Salon</Breadcrumb.Item>
					</Breadcrumb>
			    </Col>
			   </Row>
			  </Container>
		    </div> 
			
			<div className="content bridal-jewellery">
			   <Container >
				 <Row className="mb-1"> 
				   <Col xl={12} lg={12} data-aos="fade-down">
					  <div className="mb-5 text-center">
					    
					  </div>
					</Col>
				  </Row>
				  <Row>
				    {lehnga_gallery.map((c, index) => {
						return (
						   <Col xl={4} lg={6} data-aos="fade-up" className="mb-3">
							   {c.image}  
						  </Col>
						 );
					  })
					 }
				  </Row>
				    
			  </Container>
			 </div>
			
			  
        </>
    )
}

export default bridal_lehnga
