import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './index.css';

function topbar() {

  return (
    <> 
	 <div data-aos="flip-left"></div>
	  <div className="topbar">
	     <Container fluid>
		  <Row>
			<Col lg={7}>
			  <p className="m-0">
			    <span className="me-4"><i className="bi bi-telephone-inbound"></i> +91 90231-30000</span>
			    <span><i className="bi bi-geo-alt"></i>  3rd Floor, Above KFC, Model Town, Jalandhar (Punjab)</span>
			  </p>
			</Col>
			 <Col lg={5} className="text-right">
			  <p className="m-0">
			    <span className="me-3">
				  <i className="bi bi-clock"></i> Mon - Sun 11:00am 19:30pm 
				</span>
			  
			    <a className="me-2" target="blank"  href="https://www.facebook.com/sabhreeyamakeupartist">
				   <i className="bi bi-facebook"></i>
				</a>
				 <a className="me-2"  target="blank" href="https://www.instagram.com/sabhreeya/?hl=en">
				   <i className="bi bi-instagram"></i>
				</a>
				 <a className="me-2"  target="blank"  href="https://www.youtube.com/sabhreeya">
				   <i className="bi bi-youtube"></i>
				</a>
				 <a className="me-2"  target="blank"  href="https://twitter.com/sabhreeya">
				   <i className="bi bi-twitter"></i>
				</a>
               </p>
			 </Col>
		  </Row>
	    </Container>
	  </div>
	</>
  );
}


export default topbar;
// JavaScript Document// JavaScript Document