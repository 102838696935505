import React from 'react'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Helmet} from "react-helmet";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import banner from './images/hair-banner.jpg'
 
import AOS from "aos";
import "aos/dist/aos.css";

const hair_style= [ {
	  image :'./images/hair-1.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/hair-2.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/hair-3.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/hair-4.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/hair-5.jpg',
	  alt_txt : 'Reception'
	},
	{
	  image :'./images/hair-6.jpg',
	  alt_txt : 'Reception'
	} 
	]

 function hair() {
     AOS.init();
     AOS.refresh();
	 
    return (
        <>
		  <Helmet> 
		   <title>Best Hairstylist in Jalandhar | Hairstyling Services in Jalandhar | Hair Extainsion Services in Punjab</title>
		   <meta name="description" content="Sabhreeya is the Best Makeup Saloon in Jalandhar. Hairstyling is necessary to compliment your makeup. Sabhreeya will make your dream of having the best hairstylist in Jalandhar a reality. We take great delight in achieving your hair styling dreams." />
		 </Helmet>
            <div className="common-banner">
		      <div className="phone_banner" style={{ background: 'url(./images/hair-banner-phone.jpg) no-repeat' }}></div>
			  <img src={banner} alt="About Us" className="img-fluid" width="1920" height="500" />
			  <Container>
			   <Row>
			    <Col lg={12}>
			      <h1>Hair Style</h1>
				    <Breadcrumb>
					  <Breadcrumb.Item as={Link} to="/" active>Home</Breadcrumb.Item>
					  <Breadcrumb.Item>Hair Style</Breadcrumb.Item>
					</Breadcrumb>
			    </Col>
			   </Row>
			  </Container>
		    </div>
			
			<div className="content bridal-jewellery">
			   <Container >
				 <Row className="mb-1"> 
				   <Col xl={12} lg={12} data-aos="fade-down">
					  <div className="mb-5 text-center">
					   <h5>Hair Style</h5>
						  <p className="text-center">Hairstyling is necessary to compliment your makeup. Sabhreeya will make your dream of having the best hairstylist in Jalandhar a reality. We take great delight in achieving your hair styling dreams. </p>
					  </div>
					</Col>
				  </Row>
				  <Row>
				   {hair_style.map((c,index) =>{
					  return (
					    <Col xl={4} lg={6} data-aos="fade-up" className="mb-3">
						  <img src={c.image} alt={c.alt_txt} className="img-fluid" width="853" height="1280"/>
						</Col>
					  );
					  })}
				   
				  </Row>
				   <Row className="mt-5 video-iframe">
				    <Col lg={4} md={6}>
					  <iframe width="100%" height="320" src="https://www.youtube.com/embed/MOQWj_3yg08" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</Col>
					 
					 
					 
				  </Row>
			  </Container>
			 </div>
        </>
    )
}

export default hair
