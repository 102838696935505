import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Banner from './images/bridal-jewellery-banner.jpg'

import Jewelery1 from './images/jewelry/31.jpg'
import Jewelery2 from './images/jewelry/1.jpg'
import Jewelery3 from './images/jewelry/2.jpg'
import Jewelery4 from './images/jewelry/3.jpg'
import Jewelery5 from './images/jewelry/4.jpg'
import Jewelery6 from './images/jewelry/5.jpg'
import Jewelery7 from './images/jewelry/6.jpg'
import Jewelery8 from './images/jewelry/7.jpg'
import Jewelery9 from './images/jewelry/8.jpg'
import Jewelery10 from './images/jewelry/9.jpg'
import Jewelery11 from './images/jewelry/10.jpg'
import Jewelery12 from './images/jewelry/11.jpg'
import Jewelery13 from './images/jewelry/12.jpg'
import Jewelery14 from './images/jewelry/13.jpg'
import Jewelery15 from './images/jewelry/14.jpg'
import Jewelery16 from './images/jewelry/15.jpg'
import Jewelery17 from './images/jewelry/16.jpg'
import Jewelery18 from './images/jewelry/17.jpg'
import Jewelery19 from './images/jewelry/18.jpg'
import Jewelery20 from './images/jewelry/19.jpg'
import Jewelery21 from './images/jewelry/20.jpg'
import Jewelery22 from './images/jewelry/21.jpg'
import Jewelery23 from './images/jewelry/22.jpg'
import Jewelery24 from './images/jewelry/23.jpg'
import Jewelery25 from './images/jewelry/24.jpg'
import Jewelery26 from './images/jewelry/25.jpg'
import Jewelery27 from './images/jewelry/26.jpg'
import Jewelery28 from './images/jewelry/27.jpg'
import Jewelery29 from './images/jewelry/28.jpg'
import Jewelery30 from './images/jewelry/29.jpg'
import Jewelery31 from './images/jewelry/30.jpg'
import Jewelery32 from './images/jewelry/32.jpg'
import Jewelery33 from './images/jewelry/33.jpg'
import Jewelery34 from './images/jewelry/34.jpg'


import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet"; 
import AOS from "aos";
import "aos/dist/aos.css";
 

const bridal_jewelry = [{
	   
	     image : <img src={Jewelery1} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
			 
	     image : <img src={Jewelery2} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery3} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery4} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery5} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery6} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery7} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery8} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery9} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery10} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery11} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery12} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery13} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery14} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery15} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery16} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery17} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery18} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery19} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery20} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery21} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery22} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery23} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery24} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery25} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery26} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery27} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery28} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery29} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 
		 {
	     image : <img src={Jewelery31} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery32} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery33} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     },
		 {
	     image : <img src={Jewelery34} alt="Bridal Jewelry" className="img-fluid" width="900" height="800" />
	     }
		  
	     
	
	]
function jewelry () {
	 AOS.init();
     AOS.refresh();
    return (
        <>
		<Helmet> 
		   <title> Bridal Jewellery on Rent in Jalandhar | Best Designer Jewelery Collection</title>
		   <meta name="description" content="Sabhreeya is the Best Makeup Saloon in Jalandhar. Get ready for the compliments as well as the camera. You are the STAR of the event. Choose your Bridal Jewellery with us. We have an amazing Bridal Jewellery line from Kundan-Polki Necklaces, American Diamond Necklaces, Antique Gold & Temple Sets, Indo-Western Jewellery, Pearl Necklaces to Precious Beaded Necklaces." />
		 </Helmet>
          <div className="common-banner">
		      <div className="phone_banner" style={{ background: 'url(./images/bridal-jewellery-phone.jpg) no-repeat' }}></div>
		      <img src={Banner} alt="Bridal Jewellery" className="img-fluid" width="1920" height="500"  />
			  <Container>
			   <Row>
			    <Col lg={12}>
			      <h1>Bridal Jewellery</h1>
				    <Breadcrumb>
					  <Breadcrumb.Item as={Link} to="/">Home</Breadcrumb.Item>
					  <Breadcrumb.Item active>Bridal Jewellery</Breadcrumb.Item>
					</Breadcrumb>
			    </Col>
			   </Row>
			  </Container>
		    </div> 
			
			<div className="content bridal-jewellery">
			   <Container >
				 <Row className="mb-1"> 
				   <Col xl={12} lg={12} data-aos="fade-down">
					  <div className="mb-5 text-center">
					   <h5>Bridal Jewellery</h5>
						  <p className="text-center">Get ready for the compliments as well as the camera. You are the STAR of the event. Choose your Bridal Jewellery with us. We have an amazing Bridal Jewellery line from Kundan-Polki Necklaces, American Diamond Necklaces, Antique Gold & Temple Sets, Indo-Western Jewellery, Pearl Necklaces to Precious Beaded Necklaces.</p>
					  </div>
					</Col>
				  </Row>
				  <Row>
              
				  {bridal_jewelry.map((c,index) =>{
					  return (
					    <Col xl={4} lg={6} data-aos="fade-up" className="mb-3">
						   <div key={index}>{c.image}</div>
						</Col>
					  );
					  })}
				  </Row>
				   <Row className="mt-5 video-iframe">
				    <Col lg={4} md={6}>
					 <iframe width="100%" height="320" src="https://www.youtube.com/embed/iUOwbIPISeA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					</Col>
					 <Col lg={4} md={6}>
					  <iframe width="100%" height="320" src="https://www.youtube.com/embed/LP7LntT7A-c" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					 </Col>
					  <Col lg={4} md={6}>
					    <iframe width="100%" height="320" src="https://www.youtube.com/embed/E-X6MZLviEQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					  </Col>
					   <Col lg={4} md={6}>
					    <iframe width="100%" height="320" src="https://www.youtube.com/embed/_6tvuj6EwyQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					  </Col>
					  <Col lg={4} md={6}>
					   <iframe width="100%" height="320" src="https://www.youtube.com/embed/HlMWAScVJOE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					  </Col>
					  <Col lg={4} md={6}>
					   <iframe width="100%" height="320" src="https://www.youtube.com/embed/ELi4Inq1FsY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					   </Col>
					    
				  </Row>
			  </Container>
			 </div>
			
			  
        </>

		
    )
    
}

export default jewelry
