import React from 'react';
import {Helmet} from "react-helmet";
import Slider from './carousel.js';
import Artist from './the-artist.js';
import Beautiful from './already-beautiful.js';
import Service from './services.js';
import BridalJewellery from './bridal-jewellery-and-lehnga.js';
import Testimonial from './testimonials.js';
import Portfolio from './gallery.js';

 
 
function mainpage() {
	return (
        <>
         
		<Helmet>
			<title>Sabhreeya | Best Bridal Saloon in Jalandhar | Bridal Makeup Artist in Jalandhar</title>
			<meta name="description" content="Sabhreeya is the Best Makeup Saloon in Jalandhar" />
		</Helmet>
          
 
           <Slider key='slider1'/>
		   <Artist key='artist1'/>
		   <Beautiful key='beautiful'/>
		   <Service key='services1'/>
		   <BridalJewellery key='BridalJewellery1'/>
		   <Testimonial key='Testimonial'/>
		   <Portfolio key='Portfolio1'/>
        </>
    )
	}

 
export default mainpage

 