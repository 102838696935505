import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom'
import NavDropdown from 'react-bootstrap/NavDropdown'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import logo from './images/logo.png';


function menu() {
 	
  return (
      <>
	    <Navbar collapseOnSelect expand="lg" sticky="top">
		  <Container fluid>
		  <Navbar.Brand as={Link} to="/">
		     <img src={logo} alt="Sabhreeya" width="220" />
		  </Navbar.Brand>
		  <Navbar.Toggle aria-controls="responsive-navbar-nav" className="text-white" />
		  <Navbar.Collapse id="responsive-navbar-nav">
			<Nav className="ms-auto">
			  <Nav.Link as={Link} to="/the-salon">The Salon</Nav.Link>
			  <Nav.Link as={Link} to="/about" >The Artist</Nav.Link>
			  <NavDropdown title="Makeup Services" id="collasible-nav-dropdown">
				<NavDropdown.Item as={Link} to="/bridal-makeup">Bridal Makeup</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/party-makeup">Party Makeup</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/engagement-makeup">Engagement Makeup</NavDropdown.Item>
			    <NavDropdown.Item as={Link} to="/reception-makeup">Reception Makeup</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/skin-services">Skin Services</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/nails">Nails</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/hair">Hair</NavDropdown.Item>
			  </NavDropdown>
			  <Nav.Link as={Link} to="/bridal-jewellery">Bridal Jewellery</Nav.Link>
			  <Nav.Link as={Link} to="/bridal-lehnga">Bridal Lehenga</Nav.Link>
			  <Nav.Link as={Link} to="/package">Makeup Packages</Nav.Link>
			  <Nav.Link as={Link} to="/contacts" className="btn">Contact Us</Nav.Link>
			 </Nav>
		  </Navbar.Collapse>
		  </Container>
		</Navbar>
	  </>
  );
}

export default menu;
 