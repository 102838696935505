import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Contact_banner from './images/about-us.jpg'
import renu from './images/Renu-pic.jpg'
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";

function about () {
	 AOS.init();
     AOS.refresh();
    return (
        <>
		<Helmet> 
		   <title>Sabhreeya | About The Makeover Artist Renu Angural</title>
		   <meta name="description" content="Sabhreeya is the Best Makeup Saloon in Jalandhar" />
		 </Helmet>
		   <div className="common-banner">
		      <div className="phone_banner" style={{ background: 'url(./images/about-phone.jpg) no-repeat' }}></div>
			  <img src={Contact_banner} alt="About Us" className="img-fluid"  width="1920" height="500" />
			  <Container>
			   <Row>
			    <Col lg={12}>
			      <h1>About The Artist</h1>
				    <Breadcrumb>
					  <Breadcrumb.Item as={Link} to="/" active>Home</Breadcrumb.Item>
					  <Breadcrumb.Item >About Us</Breadcrumb.Item>
					</Breadcrumb>
			    </Col>
			   </Row>
			  </Container>
		    </div>
			
          <div className="theartist inner-content">
		   <Container>
		     <Row> 
			    <Col lg={7}>
				  <div className="box" data-aos="fade-right"  data-aos-duration="3000">
				   <h2>The Makeover Artist - Renu Angural</h2>
				   <p>
				     Renu, Makeover Artist in Jalandhar, Punjab India is a well known and famous makeup artist who rules the makeup industry worldwide and creates magic with her skills and makes everyone spellbound with her extraordinary talent. Optimally praised as the Best Makeup Artist in Jalandhar, Punjab India, and around the globe with her modernized magic and establishment for style and beauty totally follows Perfection.  </p>

 <p>Always dedicated to the complete picture - the most significant cosmetics, hairstyle, and accessories and believes every bit and element should be synergistic to weave an eccentric portrait of total beauty with prominent style.  Renu passion and love for flawless makeover no doubt makes her stand out exceptionally and unmatched in the field of the makeup industry. </p>
				   </div>
				</Col>
				 <Col lg={5} className="aos-all" data-aos="fade-left"  data-aos-duration="3000">
				   <img src={renu} alt="The Artist" className="img-fluid" width="800" height="950"/>
				</Col>
			 </Row>
		   </Container>
		 </div>
		 
		  <div className="experience">
		    <div className="theartist-bg" style={{ background: 'url(./images/the-artist-bg.jpg)no-repeat' }}></div>
			 <Container>
		       <Row> 
			    <Col lg={3} md={6}>
				  <div className="box">
				    <h3>15+ Years</h3>
					<p>Experience</p>
				  </div>
				</Col>
				<Col lg={3} md={6}>
				  <div className="box">
				    <h3>IEA 19 </h3>
					<p>Award Winner</p>
				  </div>
				</Col>
				<Col lg={3} md={6}>
				  <div className="box">
				    <h3>2000 +</h3>
					<p>Our Clients</p>
				  </div>
				</Col>
				<Col lg={3} md={6}>
				  <div className="box">
				    <h3>100%</h3>
					<p>Satisfied Customers</p>
				  </div>
				</Col>
			  </Row>
		   </Container>
		  </div>
			
        </>
    )
}

export default about
