import React from 'react'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Helmet} from "react-helmet"; 
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import banner from './images/engagement-banner.jpg'
 

import AOS from "aos";
import "aos/dist/aos.css";

const engagement = [{
	  image :'./images/gallery-3.jpg',
	  alt_txt : 'Bridal Jewelry'
	},
	{
	  image :'./images/gallery-2.jpg',
	  alt_txt : 'Bridal Jewelry'
	},
	{
	  image :'./images/gallery-13.jpg',
	  alt_txt : 'Bridal Jewelry'
	},
	{
	  image :'./images/gallery-12.jpg',
	  alt_txt : 'Bridal Jewelry'
	},
	{
	  image :'./images/gallery-5.jpg',
	  alt_txt : 'Bridal Jewelry'
	},
	{
	  image :'./images/gallery-7.jpg',
	  alt_txt : 'Bridal Jewelry'
	}
	]
 
 function engagement_makeup () {
	
	 AOS.init();
     AOS.refresh();
	
    return (
        <>
		 <Helmet> 
		   <title>Engagement Makeup in Punjab | Best Engagement Makeover Services in Jalandhar | Special occasional Makeover in Jalandhar</title>
		   <meta name="description" content="On a special event like your Engagement Day or Night, Sabhreeya knows how to improve your skin, features, and how accentuate them. We recommend several looks based on a variety of criteria such as your attire, time of day, stage lighting, and so on, in order to offer you the best camera-ready makeup." />
		 </Helmet>
            <div className="common-banner">
			  <div className="phone_banner" style={{ background: 'url(./images/engagement-banner-phone.jpg) no-repeat' }}></div>
		      <img src={banner} alt="About Us" className="img-fluid"  width="1920" height="500"  />
			  <Container>
			   <Row>
			    <Col lg={12}>
			      <h1>Engagement Makeup</h1>
				    <Breadcrumb>
					  <Breadcrumb.Item as={Link} to="/" active>Home</Breadcrumb.Item>
					  <Breadcrumb.Item>Engagement Makeup</Breadcrumb.Item>
					</Breadcrumb>
			    </Col>
			   </Row>
			  </Container>
		    </div>
			
			<div className="content bridal-jewellery">
			   <Container >
				 <Row className="mb-1"> 
				   <Col xl={12} lg={12} data-aos="fade-down">
					  <div className="mb-5 text-center">
					   <h5>Engagement Makeup</h5>
						  <p className="text-center">On a special event like your Engagement Day or Night, Sabhreeya knows how to improve your skin, features, and how accentuate them. We recommend several looks based on a variety of criteria such as your attire, time of day, stage lighting, and so on, in order to offer you the best camera-ready makeup. </p>
					  </div>
					</Col>
				  </Row>
				  <Row>
				   {engagement.map((c,index) =>{
					  return (
					    <Col xl={4} lg={6} data-aos="fade-up" className="mb-3">
						  <img src={c.image} alt={c.alt_txt} className="img-fluid" width="853" height="1280" />
						</Col>
					  );
					  })}
				   
				  </Row>
				  
				    <Row className="mt-5 video-iframe">
				    <Col lg={4} md={6}>
					  <iframe width="100%" height="320" src="https://www.youtube.com/embed/W3hooxtDtRA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</Col>
					 <Col lg={4} md={6}>
					  <iframe width="100%" height="320" src="https://www.youtube.com/embed/csOKXuCUZqs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</Col>
					 <Col lg={4} md={6}>
					  <iframe width="100%" height="320" src="https://www.youtube.com/embed/_1zCTIvkZe0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</Col>
					 <Col lg={4} md={6}>
					  <iframe width="100%" height="320" src="https://www.youtube.com/embed/Eqc25MAuImU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</Col>
					 
					 
				  </Row>
			  </Container>
			 </div>
        </>
    )
}

export default engagement_makeup
