import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import AOS from "aos";
import "aos/dist/aos.css";
 

export default function Portfolio() {
     AOS.init();
     AOS.refresh();

 
  const gallery = [{
            image: './images/gallery-1.jpg',
            alt_text: 'Gallery'
        },
		{
            image: './images/gallery-2.jpg',
            alt_text: 'Gallery'
        } ,
		{
            image: './images/gallery-3.jpg',
            alt_text: 'Gallery'
        } ,
		{
            image: './images/gallery-4.jpg',
            alt_text: 'Gallery'
        },
		{
            image: './images/gallery-5.jpg',
            alt_text: 'Gallery'
        },
		{
            image: './images/gallery-6.jpg',
            alt_text: 'Gallery'
        },
		{
            image: './images/gallery-7.jpg',
            alt_text: 'Gallery'
        },
		{
            image: './images/gallery-8.jpg',
            alt_text: 'Gallery'
        }     
    ];
 
  return (
    <>
	<div className="gallery">
	 <Container fluid>
	    {/* <Row>
		
		</Row> */}
	    <Row>
			 <Col>
		   <h2>The Artists Portfolio</h2>
		 </Col>
			 <Col lg={12}>
				<iframe src="https://widget.taggbox.com/140522" style={{width:'100%', height: '100vh', overflow:' auto',border: 'none' }}></iframe>
				</Col>
		   {/* <Col lg={12} className="m-auto" >
		       <Row>
			   {gallery.map((c, index) => {
			    return (
					<Col xl={3} lg={4} md={6} data-aos="zoom-out-up" data-aos-duration="3000">
					  <div className="gallery-box">
						<img src={c.image} alt={c.alt_text} className="img-fluid" width="853" height="1280"/>
					   </div>
					 </Col>
					);
				})} 
			  </Row>  
			 <Row>
			  <Col>
			   <Button  className="btn" as={Link} to="./portfolio">View More</Button>  
			 </Col> 
			  
			</Row>
		   </Col> */}
	    </Row>
	  </Container>
    </div>
	</>
  );
}

 
// JavaScript Document// JavaScript Document